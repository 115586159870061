import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonButton,
  IonAlert,
  IonList,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React from "react";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import AppsComponent from "../../components/AppsComponent";
import { logoAndroid } from "ionicons/icons";

export default class extends React.Component {
  state: any = {
    apps: null,
  };

  componentDidMount() {
    this.getStatistics();
  }

  async getStatistics() {
    let res = await API.microPartnerGetApps();
    if (res.success) {
      this.setState({
        apps: res.data,
      });
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Apps</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.state.apps == null ? (
            <LoadingFullScreen />
          ) : this.state.apps.length == 0 ? (
            <div style={{ textAlign: "center", paddingTop: 64 }}>
              <p>You don't have apps. Ask your admin to share app</p>
            </div>
          ) : (
            <IonList>
              {this.state.apps.map((app: any) => (
                <IonItem key={app.id}>
                  <IonIcon slot="start" icon={logoAndroid} />
                  <IonLabel>
                    <IonGrid style={{ padding: 0 }}>
                      <IonRow>
                        <IonCol style={{ padding: 0 }}>
                          <h2>{app.link}</h2>
                        </IonCol>
                        <IonCol style={{ padding: 0 }}>
                          <h2>Pagename: {app.pagename}</h2>
                          <h2>Domain: {app.domain}</h2>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {app.is_compiling ? (
                      <p>Compiling...</p>
                    ) : (
                      <>
                        <p>Installs: {app.users_count}</p>
                        <p
                          style={{ color: "green" }}
                          onClick={() => {
                            window.open(
                              `https://${app.domain}/${app.pagename}`,
                              "_blank"
                            );
                          }}
                        >
                          Download page
                        </p>
                      </>
                    )}
                  </IonLabel>
				  <IonButtons>
              <IonButton routerLink={`/micropartner/${app.id}/push`} color="success">
                PUSH
              </IonButton>
            </IonButtons>
                </IonItem>
              ))}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
