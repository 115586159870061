import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonButton,
  IonAlert,
  IonItem,
  IonLabel,
  IonMenuButton,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

export default class extends React.Component<Props> {
  state: any = {
    apps: null,
  };
  componentDidMount() {
    this.testerGetApps();
  }

  testerGetApps() {
    API.testerGetApps().then((res) => {
      this.setState({
        apps: res.data,
      });
    });
  }

  render() {
    const apps = this.state.apps;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Testing apps</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          {apps == null ? (
            <LoadingFullScreen />
          ) : apps.length == 0 ? (
            <div style={{ textAlign: "center", paddingTop: 64 }}>
              <p>No apps</p>
            </div>
          ) : (
            <IonList>
              {apps.map((a:any) => {
                return <IonItem key={'app_' + a.id} >
                  <IonLabel>
                    <h3 onClick={() => {
                      navigator.clipboard.writeText(a.full_link);
                    }} >[{a.id}] {a.full_link}</h3>
                    <p style={{color: a.is_compiling == 1 ? 'orange' : "green"}} >Is compiling: {a.is_compiling == 1 ? "YES" : "NO"}</p>
                    <p style={{color: !a.last_tested ? 'red' : "green"}} >Tested: {a.last_tested ? new Date(a.last_tested).toLocaleString() : "NEVER"}</p>
                  </IonLabel>
                  <IonButtons slot="end">
                    <IonButton onClick={async () => {
                      let res = await API.testerTestedApp(a.id);
                      this.testerGetApps();
                    }} >TESTED</IonButton>
                  </IonButtons>
                </IonItem>
              })}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
