import {
  IonApp,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import API from "../API";
import UserService from "../UserService";

export default class extends React.Component {
  login = "";
  password = "";

  render() {
    return (
      <IonApp>
        <IonContent
          style={{
            textAlign: "center",
          }}
        >
          <div style={{ paddingTop: "30vh" }}>
            <h1>TIVIT Apps</h1>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 30,
              left: 30,
              right: 30,
              textAlign: "center",
            }}
          >
            <IonItem>
              <IonLabel position="floating">Login</IonLabel>
              <IonInput
                onIonChange={(e) => (this.login = e.detail.value!)}
                placeholder="Логин"
                type="text"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                onIonChange={(e) => (this.password = e.detail.value!)}
                placeholder="Пароль"
                type="password"
              ></IonInput>
            </IonItem>

            <IonButton
              onClick={async () => {
                let res = await API.auth(this.login, this.password);
                if (res.success) {
                  UserService.setUser(res.data);
                  UserService.setAccessToken(res.data.session.token);
                  document.location.href = "/";
                }
              }}
            >
              Sign In
            </IonButton>

            <br />
            <br />
            <br />
            <p>
              Don't have an account?
            </p>
            <IonButton routerLink="register">Create one</IonButton>
          </div>
        </IonContent>
      </IonApp>
    );
  }
}
