import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonItemDivider,
  IonListHeader,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonModal,
  IonButton,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import { Chart } from "react-charts";
import { ImagePicker } from "react-file-picker";
import TypeSelectorComponent from "../../components/TypeSelectorComponent";
import { logoAppleAppstore } from "ionicons/icons";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

export default class extends React.Component<Props> {
  state: any = {
    app: null,
    linkStartsWithoutSSL: false,
  };
  componentDidMount() {
    this.getAppInfo();
  }

  getAppInfo() {
    API.getAppInfo(this.props.match.params.id).then((res) => {
      this.setState({
        app: res.data,
      });
    });
  }

  render() {
    const app = this.state.app;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/apps"></IonBackButton>
            </IonButtons>
            <IonTitle>Details #{this.props.match.params.id}</IonTitle>
            <IonButtons slot="end">
              <IonButton routerLink={`${this.props.match.params.id}/push`}>
                PUSH
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {app == null ? (
            <LoadingFullScreen />
          ) : (
            <IonCard>
              <IonCardHeader>
                {app.data.length != 0 ? (
                  <div style={{ height: 500 }}>
                    <Chart
                      data={[
                        {
                          label: "Installs",
                          data: app.data.map((stat: any) => {
                            return [new Date(stat[0]), parseInt(stat[1])];
                          }),
                        },
                      ]}
                      axes={[
                        { primary: true, type: "utc", position: "bottom" },
                        { type: "linear", position: "left" },
                      ]}
                      dark
                      tooltip
                      primaryCursor
                    />
                  </div>
                ) : (
                  <p>Нет данных для отображения графика</p>
                )}
              </IonCardHeader>

              <IonCardContent>
                <IonList>
                  <IonItemDivider>Информация</IonItemDivider>
                  <IonItem>
                    <IonLabel>
                      <h2>Информация</h2>
                      <p>Установок: {app.users_count} </p>
                      {/* <p>Регистраций: {app.reg_count}</p>
                      <p>Депозитов: {app.dep_count}</p> */}
                    </IonLabel>
                  </IonItem>
                  <IonItemDivider>Управление</IonItemDivider>

                  <IonItem>
                    <IonLabel position="floating">
                      Ссылка (Должна начинаться с http:// или https://){" "}
                      <span style={{ color: "yellow" }}>
                        {this.state.linkStartsWithoutSSL
                          ? "Не рекомендуем ставить ссылку без SSL"
                          : ""}
                      </span>
                    </IonLabel>
                    <IonInput
                      value={app.link}
                      onIonChange={(e) => {
                        app.link = e.detail.value!;
                        this.setState({
                          linkStartsWithoutSSL: app.link.startsWith("http://"),
                        });
                      }}
                    ></IonInput>
                  </IonItem>
                  <br />
                  <IonButtons>
                    <IonButton
                      color="success"
                      onClick={async () => {
                        let res = await API.updateAppInfo(app);
                        if (res.success) {
                          alert("App updated");
                          this.getAppInfo();
                        }
                      }}
                    >
                      Update Info
                    </IonButton>
                  </IonButtons>
                </IonList>
              </IonCardContent>
            </IonCard>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
