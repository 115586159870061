import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonList,
  IonButton,
  IonAlert,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {}

export default class extends React.Component<Props> {
  state: any = {
    app: null,
    linkStartsWithoutSSL: false,
    showCreatePushModal: false,
  };
  componentDidMount() {
    this.getPushList();
  }

  getPushList() {
    API.getPushList(this.props.match.params.id).then((res) => {
      this.setState({
        pushList: res.data,
      });
    });
  }

  render() {
    const pushList = this.state.pushList;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/apps"></IonBackButton>
            </IonButtons>
            <IonTitle>Push history #{this.props.match.params.id}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  this.setState({ showCreatePushModal: true });
                }}
              >
                Send new push
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonAlert
            isOpen={this.state.showCreatePushModal}
            onDidDismiss={() => {
              this.setState({ showCreatePushModal: false });
            }}
            header="Create new push"
            inputs={[
              {
                name: "title",
                type: "text",
                placeholder: "Title",
              },
              {
                name: "content",
                type: "text",
                placeholder: "Message",
              },
            ]}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
              },
              {
                text: "Send PUSH",
                handler: async (inputs) => {
                  console.log(inputs);
                  let res = await API.sendPush(
                    this.props.match.params.id,
                    inputs.title,
                    inputs.content
                  );
                  if (res.success) {
                    this.getPushList();
                  }
                },
              },
            ]}
          />

          {pushList == null ? (
            <LoadingFullScreen />
          ) : pushList.length == 0 ? (
            <div style={{ textAlign: "center", paddingTop: 64 }}>
              <p>You have not sent push notifications</p>
            </div>
          ) : (
            <IonList>
              {pushList.map((p:any) => {
                return <IonItem key={'push_' + p.id} >
                  <IonLabel>
                    <h3>{p.title}</h3>
                    <p>{p.content}</p>
                    <p>Receipents: {p.recipients_count} | Clicked: {p.clicked_count}</p>
                  </IonLabel>
                  <IonButtons slot="end">
                    <IonButton onClick={async () => {
                      let res = await API.updatePushStats(this.props.match.params.id, p.id);
                      this.getPushList();
                    }} >Update statistics</IonButton>
                  </IonButtons>
                </IonItem>
              })}
            </IonList>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
