import {
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { checkmark, logoAndroid } from "ionicons/icons";
import React from "react";

interface Props {
  apps: Array<any>;
}

export default class extends React.Component<Props> {
  render() {
    return (
      <IonList>
        {this.props.apps.map((app: any) => (
          <IonItem key={app.id}>
            <IonIcon slot="start" icon={logoAndroid} />
            <IonLabel>
              <IonGrid style={{ padding: 0 }}>
                <IonRow>
                  <IonCol style={{ padding: 0 }}>
                    <h2>{app.link}</h2>
                  </IonCol>
                  <IonCol style={{ padding: 0 }}>
                    <h2>Pagename: {app.pagename}</h2>
                    <h2>Domain: {app.domain}</h2>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {app.is_compiling ? (
                <p>Compiling...</p>
              ) : (
                <>
                  <p>Installs: {app.users_count}</p>
                  <p
                    style={{ color: "green" }}
                    onClick={() => {
                      window.open(
                        `https://${app.domain}/${app.pagename}`,
                        "_blank"
                      );
                    }}
                  >
                    Landing page
                  </p>
                  <input style={{width: 350}} value={`https://${app.domain}/${app.pagename}`} readOnly={true} />
                </>
              )}
            </IonLabel>
            <IonButtons>
              <IonButton routerLink={"/apps/" + app.id} color="success">
                Details
              </IonButton>
            </IonButtons>
          </IonItem>
        ))}
      </IonList>
    );
  }
}
