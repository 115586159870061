import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonButton, IonAlert, IonList, IonItem, IonLabel, IonItemDivider, IonProgressBar, IonInput } from "@ionic/react";
import React, { RefObject } from "react";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";

export default class extends React.Component {
	pickerRef: RefObject<HTMLInputElement>;

	state: any = {
		landings: null,
		loading: false,
		projectName: "",
		percent: 0,
	};

	constructor(props: any) {
		super(props);
		this.pickerRef = React.createRef();
	}

	async onZipChoosed(e: any) {
		if (e.target.files.length == 0) {
			return;
		}

		this.setState({
			loading: true,
		});

		API.uploadLanding(
			e.target.files[0],
			this.state.projectName,
			(percent) => {
				console.log(percent);
				this.setState({
					percent: percent,
				});
			},
			(res) => {
				console.log(res);
				if (res.success) {
					alert("Лендинг успешно загружен!");
					window.location.reload();
				} else {
					alert(JSON.stringify(res.error));
				}
			}
		);
	}

	componentDidMount() {
		this.getLandings();
	}

	async getLandings() {
		let res = await API.getLandings();
		if (res.success) {
			this.setState({
				landings: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<input ref={this.pickerRef} style={{ display: "none" }} type="file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" onChange={this.onZipChoosed.bind(this)} />
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Landings</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonItemDivider>Upload landing</IonItemDivider>

					{this.state.loading ? (
						<div className="ion-padding">
							<h2>Загрузка ({Math.round(this.state.percent * 100)}%)</h2>
							<IonProgressBar value={this.state.percent}></IonProgressBar>
						</div>
					) : (
						<div className="ion-padding">
							<p>Нужно заархивировать проект в формате zip и загрузить его.</p>

							<IonInput
								placeholder="Landing title"
								onIonChange={(e) =>
									this.setState({
										projectName: e.detail!.value,
									})
								}></IonInput>

							<IonButton
								disabled={this.state.projectName.length == 0}
								onClick={() => {
									this.pickerRef.current?.click();
								}}>
								Upload landing
							</IonButton>
						</div>
					)}
					<br />

					<IonItemDivider>Already uploaded landings</IonItemDivider>
					{this.state.landings == null ? (
						<LoadingFullScreen />
					) : this.state.landings.length == 0 ? (
						<div style={{ textAlign: "center", paddingTop: 64 }}>
							<p>You doesn't have landings</p>
						</div>
					) : (
						<IonList>
							{this.state.landings.map((l: any) => {
								return (
									<IonItem>
										<IonLabel>
											<h2>{l.title}</h2>
                      <p>{new Date(l.created).toString()}</p>
										</IonLabel>
									</IonItem>
								);
							})}
						</IonList>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
