import axios from "axios";
import { serverAddress } from "./Constants";
import UserService from "./UserService";

export default class {
  static headers = { "Content-type": "application/json" };

  static errorMiddleware(request: any) {
    if (request.success == false) {
      if (request.code == 1) {
        UserService.dropUserData();
        alert("Неккоректный токен. Нужно переавторизоваться");
        document.location.href = "/auth";
      } else {
        alert(JSON.stringify(request.error));
      }
    }
    return request;
  }

  static getBody(data: any = {}) {
    if (UserService.isLogged()) {
      return JSON.stringify({
        accessToken: UserService.getToken(),
        ...data,
      });
    } else {
      return JSON.stringify({
        ...data,
      });
    }
  }

  static async register(name: string, login: any, password: any) {
    let responce = await fetch(serverAddress + "register", {
      method: "POST",
      body: this.getBody({
        name: name,
        login: login,
        password: password,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async auth(login: any, password: any) {
    let responce = await fetch(serverAddress + "auth", {
      method: "POST",
      body: this.getBody({
        login: login,
        password: password,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getProfile() {
    let responce = await fetch(serverAddress + "profile", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getApps() {
    let responce = await fetch(serverAddress + "apps/list", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async createApp(link: string, pagename : string, landingId : any, apkName : string, domain: string ) {
    let responce = await fetch(serverAddress + "apps/create3", {
      method: "POST",
      body: this.getBody({
        link: link,
        pagename: pagename,
        landingId: landingId,
        apkName: apkName,
        domain: domain
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }


  static async getAppInfo(id: string) {
    let responce = await fetch(serverAddress + "apps/info", {
      method: "POST",
      body: this.getBody({
        id: id
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async updateAppInfo(app: any) {
    let responce = await fetch(serverAddress + "apps/update-app", {
      method: "POST",
      body: this.getBody({
        app: app,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getPushList(appId: string) {
    let responce = await fetch(serverAddress + "apps/push", {
      method: "POST",
      body: this.getBody({
        id: appId
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async sendPush(appId: string, title: string, content: string) {
    let responce = await fetch(serverAddress + "apps/new-push", {
      method: "POST",
      body: this.getBody({
        id: appId,
        title: title,
        content: content
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async updatePushStats(appId: any, pushId: any) {
    let responce = await fetch(serverAddress + "apps/update-push-stats", {
      method: "POST",
      body: this.getBody({
        appId: appId,
        pushId: pushId
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }


  static async pusherGetPushList() {
    let responce = await fetch(serverAddress + "pusher/history", {
      method: "POST",
      body: this.getBody({
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async pusherSendPush(title: string, content: string) {
    let responce = await fetch(serverAddress + "pusher/send", {
      method: "POST",
      body: this.getBody({
        title: title,
        content: content
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }


  static async getLandings() {
    let responce = await fetch(serverAddress + "landing/list", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async uploadLanding(landing: any, title: string, percentCompleted: (percent : number) => void, didCompleted: (res : any) => void ) {
    const formData = new FormData();
    formData.append("landing", landing);
    formData.append("accessToken", UserService.getToken());
    formData.append("title", title);

    axios.request({
        method: "POST",
        url: serverAddress + "landing/upload",
        data: formData,
        
        onUploadProgress: (p) => {
          percentCompleted(1 / p.total * p.loaded);
        },
      })
      .then((data) => {
        didCompleted(data.data);
      });
    return;
  }

  static async getDomains() {
    let responce = await fetch(serverAddress + "domains/list", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async testerGetApps() {
    let responce = await fetch(serverAddress + "tester/apps", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async testerTestedApp(id: any) {
    let responce = await fetch(serverAddress + "tester/tested", {
      method: "POST",
      body: this.getBody({
        id: id
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }


  static async microPartnerGetApps() {
    let responce = await fetch(serverAddress + "micropartner/apps/list", {
      method: "POST",
      body: this.getBody(),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }


  static async microPartnerGetPushList(appId: string) {
    let responce = await fetch(serverAddress + "micropartner/apps/push", {
      method: "POST",
      body: this.getBody({
        id: appId
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async micropartnerSendPush(appId: string, title: string, content: string) {
    let responce = await fetch(serverAddress + "micropartner/apps/new-push", {
      method: "POST",
      body: this.getBody({
        id: appId,
        title: title,
        content: content
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

}
