import {
  IonApp,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import API from "../API";
import UserService from "../UserService";

export default class extends React.Component {
  name = "";
  login = "";
  password = "";
  passwordConfirm = "";

  render() {
    return (
      <IonApp>
        <IonContent
          style={{
            textAlign: "center",
          }}
        >
          <div style={{ paddingTop: "30vh" }}>
            <h1>TIVIT Apps</h1>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 30,
              left: 30,
              right: 30,
              textAlign: "center",
            }}
          >
            <IonItem>
              <IonLabel position="floating">Your name</IonLabel>
              <IonInput
                onIonChange={(e) => (this.name = e.detail.value!)}
                placeholder="Name"
                type="text"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Login</IonLabel>
              <IonInput
                onIonChange={(e) => (this.login = e.detail.value!)}
                placeholder="Login"
                type="text"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                onIonChange={(e) => (this.password = e.detail.value!)}
                placeholder="Password"
                type="password"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Confirm password</IonLabel>
              <IonInput
                onIonChange={(e) => (this.passwordConfirm = e.detail.value!)}
                placeholder="Confirm password"
                type="password"
              ></IonInput>
            </IonItem>

            <IonButton
              onClick={async () => {
                if (this.password != this.passwordConfirm) {
                  return alert("Password mismatch");
                }
                if (this.login.length == 0) {
                  return alert("Login is empty");
                }

                if (this.name.length == 0) {
                  return alert("Name is empty");
                }

                let res = await API.register(
                  this.name,
                  this.login,
                  this.password
                );
                if (res.success) {
                  UserService.setUser(res.data);
                  UserService.setAccessToken(res.data.session.token);
                  document.location.href = "/";
                }
              }}
            >
              Войти
            </IonButton>
          </div>
        </IonContent>
      </IonApp>
    );
  }
}
