import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import {
  albumsOutline,
  analyticsSharp,
  appsSharp,
  personSharp,
} from "ionicons/icons";
import "./Menu.css";
import AppListPage from "../pages/Admin/AppListPage";
import UserService from "../UserService";
import LandingsPage from "../pages/Admin/LandingsPage";

interface AppPage {
  url: string;
  icon: string;
  title: string;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
  {
    title: "Apps",
    url: "/apps",
    icon: appsSharp,
    component: AppListPage,
  }, 
  {
    title: "Landings",
    url: "/landing",
    icon: albumsOutline,
    component: LandingsPage,
  }, 
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>TIVIT Apps</IonListHeader>
          <IonNote>{UserService.getUser().role == "admin" ? "Admin mode" : ""}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  style={{
                    "--height": 20,
                  }}
                  className={
                    location.pathname.startsWith(appPage.url) ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon icon={appPage.icon} size={"4"} />
                  <IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
  );
};

export default Menu;
