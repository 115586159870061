import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonButton, IonAlert } from "@ionic/react";
import React from "react";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import AppsComponent from "../../components/AppsComponent";

export default class extends React.Component {
	state: any = {
		apps: null,
	};

	componentDidMount() {
		this.getStatistics();
	}

	async getStatistics() {
		let res = await API.getApps();
		if (res.success) {
			this.setState({
				apps: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Apps</IonTitle>
						<IonButtons slot="end">
							<IonButton routerLink="/createapp">Create app</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.apps == null ? (
						<LoadingFullScreen />
					) : this.state.apps.length == 0 ? (
						<div style={{ textAlign: "center", paddingTop: 64 }}>
							<p>You doesn't have apps. You can create new app</p>
							<IonButton routerLink="/createapp">Create app</IonButton>
						</div>
					) : (
						<AppsComponent apps={this.state.apps} />
					)}
				</IonContent>
			</IonPage>
		);
	}
}
