import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonButton, IonAlert, IonItem, IonLabel, IonRadioGroup, IonRadio, IonListHeader, IonInput, IonItemDivider, IonLoading, IonSpinner } from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../../API";
import LoadingFullScreen from "../../components/LoadingFullScreen";

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		landings: [],
		domains: [],
		selectedLandingId: null,
		selectedDomain: null,
		apkFileName: "tivit.apk",
		link: null,
		pagename: "",
		loading: false,
	};
	componentDidMount() {
		API.getLandings().then((res) => {
			this.setState({
				landings: res.data,
			});
		});

		API.getDomains().then((res) => {
			this.setState({
				domains: res.data,
			});
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Create app</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList>
						<IonItemDivider>Select landing page</IonItemDivider>
						<IonRadioGroup value={this.state.selectedLandingId} onIonChange={(e) => this.setState({ selectedLandingId: e.detail.value! })}>
							{this.state.landings.map((l: any) => {
								return (
									<IonItem key={"landing_" + l.id.toString()}>
										<IonLabel>
											<h2>{l.title}</h2>
										</IonLabel>
										<IonRadio slot="end" value={l.id.toString()} />
									</IonItem>
								);
							})}
						</IonRadioGroup>
						<IonItemDivider>Select domain</IonItemDivider>
						<IonRadioGroup value={this.state.selectedDomain} onIonChange={(e) => this.setState({ selectedDomain: e.detail.value! })}>
							{this.state.domains.map((d: any) => {
								return (
									<IonItem key={"domain_" + d}>
										<IonLabel>
											<h2>{d}</h2>
										</IonLabel>
										<IonRadio slot="end" value={d} />
									</IonItem>
								);
							})}
						</IonRadioGroup>
						<IonItemDivider>APK file name</IonItemDivider>
						<IonItem>
							<IonInput value={this.state.apkFileName} placeholder="Enter APK filename" onIonChange={(e) => this.setState({ apkFileName: e.detail.value! })} clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonInput value={this.state.link} placeholder="Enter webview URL" onIonChange={(e) => this.setState({ link: e.detail.value!.startsWith("http") ? e.detail.value! : "https://" + e.detail.value! })} clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonInput value={this.state.pagename} placeholder="Pagename (optional)" onIonChange={(e) => this.setState({ pagename: e.detail.value! })} clearInput></IonInput>
						</IonItem>
					</IonList>
					<div className="ion-padding">
						<IonButtons>
							<IonButton
								onClick={async () => {
									this.setState({
										loading: true,
									});

                                    if (this.state.loading) {
                                        return
                                    }
									let res = await API.createApp(this.state.link, this.state.pagename, this.state.selectedLandingId, this.state.apkFileName, this.state.selectedDomain);
									console.log(res);

									if (res.success) {
										window.location.href = "/apps/" + res.data.id;
									}
								}}
								color="success"
								disabled={this.state.apkFileName.length == 0 || this.state.selectedLandingId == null || this.state.link == null || this.state.selectedDomain == null}>
								{this.state.loading ? <IonSpinner></IonSpinner> : false}&nbsp;Create app
							</IonButton>
						</IonButtons>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
