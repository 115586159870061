import { IonSpinner } from "@ionic/react";
import React from "react";

export default class extends React.Component {
  render() {
    return (
      <div
        style={{
          paddingTop: "40vh",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      >
        <img height={50} src="/assets/loading.gif" alt=""/>
        <p>Загрузка...</p>
      </div>
    );
  }
}
