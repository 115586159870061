import * as PartnersMenu from "./components/PartnersMenu";
import * as PusherMenu from "./components/PusherMenu";
import * as TesterMenu from "./components/TesterMenu";
import * as MicroPartnerMenu from "./components/MicroPartnerMenu";

import React from "react";
import {
  IonApp,
  IonButton,
  IonIcon,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import UserService from "./UserService";
import AuthPage from "./pages/AuthPage";
import ChooseAppPage from "./pages/Admin/ChooseAppPage";

import API from "./API";
import { logOut } from "ionicons/icons";
import RegisterPage from "./pages/RegisterPage";
import PushHistoryPage from "./pages/Admin/PushHistoryPage";
import CreateAppPage from "./pages/Admin/CreateAppPage";
import MicroPartnerPushHistoryPage from "./pages/Micropartner/MicroPartnerPushHistoryPage";

function getMenu() {
  switch (UserService.getUser().role) {
    case "partner":
      return <PartnersMenu.default />;
    case "admin":
      return <PartnersMenu.default />;
    case "pusher":
      return <PusherMenu.default />;
    case "tester":
      return <TesterMenu.default />;
    case "micropartner":
      return <MicroPartnerMenu.default />;
    default:
      break;
  }
}

function getOutlets() {
  switch (UserService.getUser().role) {
    case "partner":
      return (
        <IonRouterOutlet id="main">
          {PartnersMenu.appPages.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              component={page.component}
              exact
            />
          ))}
          <Route path="/apps/:id/" component={ChooseAppPage} exact />
          <Route path="/apps/:id/push" component={PushHistoryPage} exact />
          <Route path="/createapp" component={CreateAppPage} exact />

          <Redirect from="/" to="/apps" exact />
        </IonRouterOutlet>
      );
    case "admin":
      return (
        <IonRouterOutlet id="main">
          {PartnersMenu.appPages.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              component={page.component}
              exact
            />
          ))}
          <Route path="/apps/:id/" component={ChooseAppPage} exact />
          <Route path="/apps/:id/push" component={PushHistoryPage} exact />
          <Route path="/createapp" component={CreateAppPage} exact />

          <Redirect from="/" to="/apps" exact />
        </IonRouterOutlet>
      );
    case "pusher":
      return (
        <IonRouterOutlet id="main">
          {PusherMenu.appPages.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              component={page.component}
              exact
            />
          ))}
          <Redirect from="/" to="/push" exact />
        </IonRouterOutlet>
      );
    case "tester":
      return (
        <IonRouterOutlet id="main">
          {TesterMenu.appPages.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              component={page.component}
              exact
            />
          ))}
          <Redirect from="/" to={TesterMenu.appPages[0].url} exact />
        </IonRouterOutlet>
      );
      case "micropartner":
        return (
          <IonRouterOutlet id="main">
            {MicroPartnerMenu.appPages.map((page) => (
              <Route
                key={page.url}
                path={page.url}
                component={page.component}
                exact
              />
            ))}
            <Redirect from="/" to={MicroPartnerMenu.appPages[0].url} exact />
            <Route path="/micropartner/:id/push" component={MicroPartnerPushHistoryPage} exact />

          </IonRouterOutlet>
        );

    default:
      break;
  }
}

class App extends React.Component {
  componentDidMount() {
    if (UserService.isLogged()) {
      this.checkAuth();
    }
  }

  async checkAuth() {
    let res = await API.getProfile();
    if (res.success && res.data) {
      UserService.setUser(res.data);
    }
  }

  render() {
    return (
      <IonApp>
        {UserService.isLogged() ? (
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <IonMenu contentId="main" type="overlay">
                {getMenu()}
                <IonButton
                  onClick={() => {
                    if (window.confirm("Вы действительно хотите выйти?")) {
                      UserService.dropUserData();
                      document.location.href = "/auth";
                    }
                  }}
                  color="danger"
                >
                  Выйти&nbsp;<IonIcon icon={logOut}></IonIcon>
                </IonButton>
              </IonMenu>

              {getOutlets()}
            </IonSplitPane>
          </IonReactRouter>
        ) : (
          <IonReactRouter>
            <IonRouterOutlet id="main">
              <Route path="/auth" component={AuthPage} exact />
              <Route path="/register" component={RegisterPage} exact />
              <Redirect from="/" to="/auth" exact />
            </IonRouterOutlet>
          </IonReactRouter>
        )}
      </IonApp>
    );
  }
}

export default App;
